.login-body {
    .form-container {
        z-index: 10000;
        .form-card {
            z-index: 100000;
            position: relative;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            .p-form-error {
                color: red;
            }
            .fl-login-header{
                text-align: center;
                img {
                    width: 200px;
                }
            }
            .btn-subcribe {
                background-color: #8faadc;
            }
        }
    }
}