.login-body {
    height: 100vh;
    background-image: url("/assets/fromlabs/background.png");
    background-repeat: repeat!important;
    background-size: 10px;
    overflow: hidden;
    .fromlabs-logo-image{
        top: 5px;
        left: 5px;
        position: absolute;
        img{
            width: 50%;
        }
    }
    .login-panel{
        border: 1px solid #8faadc;
        z-index: 1000;
        .login-panel-content{
            padding: 5px 100px!important;
            h1 {
                font-size: xx-large!important;
                color: #8faadc;
                font-weight: unset;
            }
            .btn-sign-in {
                background-color: #8faadc;
            }
        }
        .fl-login-header{
            background-color: #8faadc!important;
            img {
                width: 50%!important;
            }
        }
    }
    .login-background-image{
        position: absolute;
        bottom: 0px;
        width: 100%;
        img {
            width: 100%;
        }
    }
}

.reset-password {
    margin-left:15px;
}

.recover-username {
    margin-left:15px;
    margin-top:3px;
    height:3vh;
}

.message-login{
    padding-top: unset!important;
    padding-bottom: unset!important;
}
